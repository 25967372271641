<template>
    <div v-if="data">
        <h4>{{ data.name }}</h4>
        <span v-if="data.about" v-html="data.about"></span>
        <div>
            <h5 v-if="data.address1" class="mt-2"><strong>Address</strong></h5>
            <span v-if="data.address1">{{ data.address1 }}<br/></span>
            <span v-if="data.address2">{{ data.address2 }}<br/></span>
            <span v-if="data.city">{{ data.city.name }}</span>
            <span v-if="data.state">, {{ data.state.name }}</span>
            <span v-if="data.postalcode"> {{ data.postalcode }}</span>
            <span v-if="data.country">, {{ data.country.name }}</span>
            <b-link v-if="data.phone" :href="'tel:' + data.phone" target="_blank"><br/>{{ data.phone }}</b-link>
            <b-link v-if="data.email" :href="'mailto:' + data.email" target="_blank"><br/>{{ data.email }}</b-link>
            <div v-if="data.map">
                <h5 class="mt-2">Location Map</h5>
                <div v-html="data.map"></div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import {
    BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BButton,
  } from 'bootstrap-vue'
  
  export default {
    components: {
      BCard,
      BImg,
      BCardBody,
      BCardText,
      BCardTitle,
      BMedia,
      BMediaAside,
      BAvatar,
      BAvatarGroup,
      BMediaBody,
      BButton,
    },
    directives: {
      'b-tooltip': VBTooltip,
    },
    computed: {
      speakers() {
        return this.$store.getters['attendees/sessionSpeakers'](this.data.speakerList)
      },
      sessionLabel() {
        let label = null
        // console.log('this.data.id', this.data);
        const sessionStart = this.$date(this.data.scheduledJoin || this.data.scheduledStart)
        const sessionEnd = this.$date(this.data.scheduledEnd)
        if (this.$date().isBefore(sessionStart)) {
          label = null
        } else if (this.$date().isBetween(sessionStart, sessionEnd, null, '[]')) {
          label = 'Join Session'
        } else if (this.$date().isAfter(sessionEnd) && this.data.recordingUrl) {
          label = 'Watch Session'
        } else if (this.$date().isAfter(sessionEnd) && !this.data.recordingUrl) {
          label = 'Recording Awaited'
        }
        // console.log('sessionlabel', label, sessionStart.toString(), sessionEnd.toString());
        return label
      }
    },
    methods: {
      showParticipantDetail(speaker) {
        // console.log('showParticipantDetail', speaker);
        if (this.showSpeakerInfo) {
          this.$store.dispatch('attendees/showSidebar', {show: true, data: speaker, chat: false, call: false, meeting: false})
        }
      },
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
      title: {
        type: Boolean,
        default: () => false,
      },
      description: {
        type: Boolean,
        default: () => false,
      },
      showSpeakerInfo: {
        type: Boolean,
        default: () => true,
      },
      location: {
        type: Boolean,
        default: () => false,
      },
      customStyle: {
        type: String,
        default: () => ''
      }
    },
  }
  </script>
  